
import React, { Suspense, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useAuth, ContextHolder } from '@frontegg/react'
import Sidebar from './components/Sidebar'
import Login from './components/Login'
import OAuthCallback from './components/OAuthCallback'
import { checkTenantAndUser } from './services/nocodbService'

// Lazy load route components
const Welcome = React.lazy(() => import('./components/pages/Welcome'))
const Reporting = React.lazy(() => import('./components/pages/Reporting'))
const Desktop = React.lazy(() => import('./components/pages/Desktop'))
const Solutions = React.lazy(() => import('./components/pages/Solutions'))
const Integrations = React.lazy(() => import('./components/pages/Integrations'))
const Portal = React.lazy(() => import('./components/pages/Portal'))
const Roadmap = React.lazy(() => import('./components/pages/Roadmap'))
const Feedback = React.lazy(() => import('./components/pages/Feedback'))

const App: React.FC = () => {
  const { isAuthenticated, user } = useAuth()

  if (!ContextHolder.getContext()) {
    return <div>Loading...</div>
  }

  useEffect(() => {
    const syncUserWithNoCoDB = async () => {
      if (isAuthenticated && user) {
        try {
          const { id: userId, name, email, tenantId, accessToken } = user;
          const tenantsResponse = await fetch('https://app-6yutcyqk6ant.us.frontegg.com/frontegg/identity/resources/users/v3/me/tenants', {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          const tenantsData = await tenantsResponse.json();
          const companyName = tenantsData?.activeTenant?.name || 'Not Found';
          
          await checkTenantAndUser(tenantId, userId, name || '', email, companyName);
        } catch (error: any) {
          console.error('Error syncing user with NoCoDB:', error);
        }
      }
    };

    syncUserWithNoCoDB();
  }, [isAuthenticated, user]);

  if (!isAuthenticated) {
    return <Login />
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 overflow-auto">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/reporting" element={<Reporting />} />
            <Route path="/desktop" element={<Desktop />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/integrations" element={<Integrations />} />
            <Route path="/portal" element={<Portal />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/oauth/callback" element={<OAuthCallback />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  )
}

export default App
